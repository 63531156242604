import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Helmet from "react-helmet"

import { globalWindow } from "../utils/constants"
import styled, { css } from "../components/styled"
import getColumnSize from "../utils/getColumnSize"
import { Container } from "../components/UI/Grid/Grid"
import Provider from "../components/Provider"
import Header from "../components/Header/Header"
import Link from "../components/UI/Link"
import { MainTitle, Description, Text } from "../components/UI/Typography"
import Footer from "../components/Footer/Footer"

const TitleContent = styled(MainTitle)`
  position: relative;
  margin-bottom: 20px;
`

const TextContent = styled(Description)`
  margin-bottom: 60px;
  max-width: 250px;

  ${({ theme: { media } }) => media.above("large")} {
    max-width: 280px;
  }
`

const Wrapper = styled.div`
  min-height: calc(100vh - 90px);
`

const Top = styled.div`
  padding: 32px ${getColumnSize(0)} 0;

  ${({ theme: { media } }) => media.above("large")} {
    padding: 100px ${getColumnSize(1)} 0;
  }
`

const Bottom = styled.div`
  width: ${getColumnSize(12)};
  padding: 40px 0 56px;

  ${({ theme: { media } }) => media.above("large")} {
    width: ${getColumnSize(10)};
    padding: 80px 0 90px ${getColumnSize(1)};
  }
`

interface TabItemProp {
  active?: boolean
  [key: string]: any
}

const TabItem = styled(Link)<TabItemProp>`
  display: inline-flex;
  align-items: center;
  white-space: nowrap;

  margin-bottom: 0px;
  margin-left: 2em;
  position: relative;

  padding-top: 0.6em;
  padding-bottom: 0.6em;

  border-bottom: 1px solid
    ${({ theme: { colors }, active }) =>
      active ? colors.blue.default : "transparent"};
  color: ${({ theme: { colors }, active }) =>
    active ? colors.blue.default : colors.grayscale.dark};

  &:first-child {
    margin-left: 0;
  }

  img {
    margin-top: -0.6em;
    margin-left: 0.4em;
    display: inline-block;
  }
`

const Navigation = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;

  max-width: 100vw;
  margin-left: -1.25em;
  margin-right: -1.25em;

  padding-left: 1.25em;
  padding-right: 1.25em;

  overflow: auto;
  -webkit-overflow-scrolling: touch;

  ${({ theme: { media } }) => css`
    ${media.above("small")} {
      overflow: visible;
      margin: 0;
      padding: 0;
    }
  `}
`

const NavigationColumn = styled.div`
  flex-wrap: nowrap;
  display: flex;
  white-space: nowrap;
`

const ManifestWrapper = styled.div`
  border-bottom: 1px solid
    ${({ theme: { colors } }) => `${colors.grayscale.mediumLight}30`};
  background-color: ${({ theme: { colors } }) => colors.grayscale.white};
`

interface ValuesContent {
  markdownRemark: {
    frontmatter: {
      title: string
      copy: string
    }
  }
}

const Manifesto: React.FC = () => {
  const data: ValuesContent = useStaticQuery(query)
  const basePath = "nominate-values"

  return (
    <Provider>
      <Helmet>
        <script>
          {`!function(w,d,i,s){function l(){if(!d.getElementById(i)){var f=d.getElementsByTagName(s)[0],e=d.createElement(s);e.type="text/javascript",e.async=!0,e.src="https://canny.io/sdk.js",f.parentNode.insertBefore(e,f)}}if("function"!=typeof w.Canny){var c=function(){c.q.push(arguments)};c.q=[],w.Canny=c,"complete"===d.readyState?l():w.attachEvent?w.attachEvent("onload",l):w.addEventListener("load",l,!1)}}(window,document,"canny-jssdk","script");`}
        </script>

        <script>
          {`
            Canny('render', {
              boardToken: '6949bdf1-6e17-d4d2-11e9-dd5980cc62bf',
              basePath: '${
                globalWindow ? (global as any).window.location.pathname : ""
              }'
            });
          `}
        </script>
      </Helmet>
      <Header bgColor="#ffffff" />
      <Wrapper>
        <ManifestWrapper>
          <Container>
            <Top>
              <TitleContent>
                {" "}
                {data.markdownRemark.frontmatter.title}
              </TitleContent>
              <TextContent> {data.markdownRemark.frontmatter.copy}</TextContent>
              <Navigation>
                <NavigationColumn>
                  <TabItem to="/manifesto">Join Movement</TabItem>
                  <TabItem
                    to="/nominate-values"
                    active={basePath === "nominate-values"}
                  >
                    Nominate Values
                  </TabItem>
                </NavigationColumn>
              </Navigation>
            </Top>
          </Container>
        </ManifestWrapper>
        <Container>
          <Bottom>
            <div data-canny />
          </Bottom>
        </Container>
      </Wrapper>
      <Footer />
    </Provider>
  )
}

export const query = graphql`
  query ValuesContent {
    markdownRemark(frontmatter: { id: { eq: "manifesto" } }) {
      frontmatter {
        title
        copy
      }
    }
  }
`

export default Manifesto
